(function ($) {

    var growTemplate = function () {
    };

    /**
     * jquery heightLine
     */
    $(function () {

        //ランキング
        $('.l-main__content  .wpp-list li:not(.is-focus) a p').heightline({
            mobile: false,
            columns: 4
        });

        $('.l-main__content  .wpp-list li.is-focus a p').heightline({
            mobile: false,
            columns: 2
        });

    });

    /**
     * owl.carousel
     */
    growTemplate.prototype.owlCarousel = function () {

        if (typeof $.fn["owlCarousel"] === "undefined") {
            return false;
        }

        var $owl = null;
        $(".c-main-visual").imagesLoaded(function(){
            $owl = $('.c-main-visual .owl-carousel').owlCarousel({
                items: 1,
                center: true,
                margin: 0,
                autoWidth: true,
                autoplay: true,
                autoplayTimeout: 5000,
                loop: true,
                dots: false,
                nav: true,
                autoplayHoverPause: false,
                autoplaySpeed: 1000,
                navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                responsive: {
                    0: {
                        items: 1,
                        center: true,
                        margin: 0,
                        autoWidth: false,
                        autoplay: true,
                        autoplayTimeout: 5000,
                        loop: true,
                        dots: false,
                        nav: true,
                        autoplayHoverPause: false,
                        autoplaySpeed: 1000,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']
                    },
                    667: {
                        items: 3,
                        center: true,
                        margin: 0,
                        autoWidth: true,
                        autoplay: true,
                        autoplayTimeout: 5000,
                        loop: true,
                        dots: false,
                        nav: true,
                        autoplayHoverPause: false,
                        autoplaySpeed: 1000,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']
                    }
                }
            });

            $('.js-rankingposts').owlCarousel({
                items: 3,
                center: false,
                margin: 30,
                autoWidth: true,
                autoplay: true,
                autoplayTimeout: 5000,
                loop: true,
                dots: false,
                nav: true,
                autoplayHoverPause: false,
                autoplaySpeed: 1000,
                startPosition: 1,
                navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                responsive: {
                    0: {
                        items: 1,
                        center: true,
                        margin: 10,
                    },
                    667: {
                        items: 1,
                        center: true,
                        margin: 30,
                    }
                }
            });


            var text = $owl.find('.active .c-main-visual__item').data('alt-text');

            if (text) {
                $('.js-slider-date').show();
                $('.js-slider-date').text(text);
            } else {
                $('.js-slider-date').hide();
                $('.js-slider-date').text(text);
            }

            $owl.on('changed.owl.carousel', function (e) {
                var self = this;
                setTimeout(function () {
                    var text = $(self).find('.active .c-main-visual__item').data('alt-text');
                    console.log(text);
                    if (text) {
                        $('.js-slider-date').show();
                        $('.js-slider-date').text(text);
                    } else {
                        $('.js-slider-date').hide();
                        $('.js-slider-date').text(text);
                    }

                }, 500);
            });
        });


    }

    /**
     * slidebars
     */
    growTemplate.prototype.slidebars = function () {
        $.slidebars({
            scrollLock: true
        });

        // その他メニューの調整
        /*
        var $otherMenuLi = $('.sb-slidebar #menu-item-8887 .sub-menu').html();

        $('.sb-slidebar .sb-slidebar__contact').after('<ul id="otherMenu"></ul>');

        $('#otherMenu').html($otherMenuLi);
        */
    }

    /**
     * ページトップヘ戻る
     */
    growTemplate.prototype.pageTop = function () {
        $('.js-pagetop').on('click', function (e) {
            e.preventDefault();
            $('body,html').animate({
                scrollTop: 0
            }, 500);
        });

        function showPageTop() {
            if (window.pageYOffset > 500) {
                $('.js-pagetop').addClass('is-show');
            } else {
                $('.js-pagetop').removeClass('is-show');
            }
            window.requestAnimationFrame(showPageTop);
        }

        window.requestAnimationFrame(showPageTop);
    }

    /**
     * ランキングテキスト
     */
    growTemplate.prototype.wppRankNumber = function () {
        var sideWidget = $('.l-sidebar .wpp-list li');
        // console.log(sideWidget);
        if (sideWidget.length === 0) {
            return false;
        }
        var rank = 0;
        for (var i = 0; i < sideWidget.length; i++) {
            var list = $(sideWidget[i]);
            list.find('strong').text(i + 1);
        }
    }

    /**
     * 追従型サイドバー
     */
    growTemplate.prototype.scrollSidebar = function () {

        var sidebar = document.getElementById("sidebar-scroll");


        if (typeof sidebar === "undefined" || $.isEmptyObject(sidebar)) {
            return false;
        }

        var localWindow = $(window);
        var footer = $('.l-footer');
        var sidebarWidth = sidebar.getBoundingClientRect().width;
        var sidebarHeight = sidebar.getBoundingClientRect().height;

        var windowInnerHeight = localWindow.height();

        var sidebarOffset = sidebar.offsetTop;


        var body = $('body');

        function scroll() {

            var pageOffset = localWindow.scrollTop();
            var footerOffset = footer.offset().top;
            var sidebarHeight = sidebar.getBoundingClientRect().height;

            var absoluteSidebarOffset = footerOffset - sidebarHeight - 40;
            if (pageOffset > sidebarOffset && (pageOffset + sidebarHeight) < footerOffset) {
                $(sidebar).addClass('is-fixed');
                $(sidebar).removeClass('is-absolute');
                $(sidebar).css({
                    "width": sidebarWidth,
                    'top': 20
                });
            }

            if (pageOffset + windowInnerHeight > footerOffset) {
                $(sidebar).addClass('is-absolute');
                $(sidebar).removeClass('is-fixed');
                $(sidebar).css({
                    "top": absoluteSidebarOffset,
                });
            }

            if (pageOffset <= sidebarOffset) {
                $(sidebar).removeClass('is-fixed');
                $(sidebar).removeClass('is-absolute');
            }
            window.requestAnimationFrame(scroll)
        }

        scroll();
        window.requestAnimationFrame(scroll)
    }

    /**
     * ページネーションの調整
     */
    growTemplate.prototype.smpPagination = function () {

        if ($(window).width() < 768) {
            var $pagination = $(".pagination");
            if(  ! $pagination.length  ){
                return false;
            }
            $pagination.find("a").each(function (nav) {
                var $nav = $(this);
                if ($nav.hasClass('next') || $nav.hasClass('prev')) {
                    $nav.closest("li").remove();
                }
            })
        }

    }

    /**
     * グローバルナビゲーションのサブナビゲーション
     */
    growTemplate.prototype.globalSubMenu = function () {

        var $gnav = $('.l-global-navigation > div > div >  div > ul > li');

        var navColors = [
            "#002C47",
            "#005CAA", // マーケティング
            "#1BA6E5", // マネジメント
            "#00B2AE", // 投資財務
            "#8FC31F", // 新規事業
            "#F39800", // 業務改善
            "#EA6172", // 海外進出
            "#AB0000", // 経営戦略
            "#666666", // 無料レポート
        ]

        $.each($gnav, function (key, nav) {
            var $nav = $(nav);
            var $submenu = $nav.find(".sub-menu").clone();
            var navKey = key;

            if ($submenu.length) {
                var $wrap = $('<div class="l-submenu"></div>');
                var $submenuTitle = $('<div class="l-submenu__title">' + $nav.find(" > a").text() + '</div>');
                $wrap.append('<div class="l-container"></div>');
                $wrap.find(".l-container").append($submenuTitle);
                $wrap.find(".l-container").append($submenu)
                $wrap.addClass("hero-menu");
                //var $submenuColor = navColors[navKey];
                var $submenuColor = '#777'; // 色を固定
                $wrap.css('background', $submenuColor);
                $(".l-header").after($wrap);
                var flag = false;
                var timeid = false;

                $nav.on('mouseover', function (e) {

                    $wrap.addClass("is-open");
                    flag = true;
                    clearTimeout(timeid);

                    $wrap.on('mouseleave', function () {
                        $wrap.removeClass("is-open");
                        flag = false;
                        clearTimeout(timeid);
                    });

                    $nav.on("mouseleave", function () {
                        $wrap.on("mouseover", function () {
                            flag = false;
                            clearTimeout(timeid);
                        });
                        if (timeid) {
                            clearTimeout(timeid);
                        }
                        timeid = setTimeout(function () {
                            if (flag) {
                                $wrap.removeClass("is-open");
                                flag = false;
                            }
                        }, 100);

                    })
                });

            }
        });

    }


    /**
     * グローバルナビ追従
     */
    growTemplate.prototype.fixedNav = function () {

        $(window).scroll(function() {
            var navStaticTop = parseInt($('.l-global-navigation').offset().top);
            var navHeight = parseInt($('.l-global-navigation .fixed-nav').height());
            var scrollTop = $(window).scrollTop();
            var windowHeight = $(window).height();

            if (scrollTop > navStaticTop) {
                $('.fixed-nav').addClass('fixed');
                $('.l-submenu').addClass('fixed');
            } else {
                $('.fixed-nav').removeClass('fixed');
                $('.l-submenu').removeClass('fixed');
            }
        });

        $(window).scroll();
    }


    /**
     * メルマガ追従バナー
     */
    growTemplate.prototype.fixedBanner = function () {

        if ($('#fixed-banner').length == 0) return false;

        $(window).scroll(function() {
            var bannerStaticTop = parseInt($('#fixed-banner').offset().top);
            var bannerHeight = parseInt($('#fixed-banner .fixed-banner-conteiner').height());
            var scrollTop = $(window).scrollTop();
            var windowHeight = $(window).height();

            if (scrollTop > 50) {
                $('#fixed-banner .fixed-banner-conteiner').addClass('show');
            } else {
                $('#fixed-banner .fixed-banner-conteiner').removeClass('show');
            }

            if (scrollTop + windowHeight > bannerStaticTop + bannerHeight) {
                $('#fixed-banner .fixed-banner-conteiner').removeClass('fixed');
            } else {
                $('#fixed-banner .fixed-banner-conteiner').addClass('fixed');
            }
        });

        $(window).scroll();
    }



    $(function () {
        var app = new growTemplate();
        app.pageTop();
        app.owlCarousel();
        app.slidebars();
        app.wppRankNumber();
        app.scrollSidebar();
        app.globalSubMenu();
        app.smpPagination();

        app.fixedNav();
        app.fixedBanner();

        new WOW().init();

        var $adblock = $('.c-ad-block');
        if ($adblock.length) {
            if ($adblock.text().trim() === "") {
                $adblock.remove();
            }
        }

        if ( $(window).width() <= 640 ){
            var categoryMenu = $('.l-footer__category__menu');
            var flag = false;
            categoryMenu.find('> ul > li > a').on('click',function(e){
                e.preventDefault();
                $(this).next().slideToggle(200);
            });
        }
    });


})(jQuery);
